import React from'react';
//routes
import {BrowserRouter, Routes, Route} from 'react-router-dom';
//pages
import Home from './pages/Home';
import About from './pages/About';
import AllProducts from './pages/AllProducts';
//components
import Navbar from './components/Navbar';
import Footer from './components/Footer/Footer';
import GlobeScene from './components/GlobeScene';
import '@fontsource/inter';
import '@fontsource/bebas-neue';
import '@fontsource/oxygen'; 

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/allproducts' element={<AllProducts />} />
        </Routes>
      </BrowserRouter>
      <Footer />
      {/* <GlobeScene/> */}

      
    </>
  );
}

export default App;