import React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid, Box, useTheme, useMediaQuery } from '@mui/material';
import GoldRolledteel from "../components/images/GoldSteel.png";
import Coilrolled from "../components/images/CoilSteel.png";
import PopCoil from "../components/images/PopCoil.png";
import GrainOriented from "../components/images/GrainOriented.png";
import GalVanished from "../components/images/GalVanishedCoil.png";
import Fabrication from "../components/AllProductsImages/Flat Fabrication.jpg";
import MoonCut from "../components/AllProductsImages/Flat Moon Cut.jpg";
import PNS from "../components/AllProductsImages/Flat PNS.jpg";

import { GradientContainer } from '../pages/AllProducts';

// Data for products (you can replace this with props or data fetched from an API)
const products = [
  {
    title: 'FABRICATION',
    image: Fabrication, 
    length: '255.44 mm',
    width: '255.44 mm',
    weight: '255.44 mm',
    thickness: '255.44 mm',
  },
  {
    title: 'MOON CUT',
    image: MoonCut,
    length: '255.44 mm',
    width: '255.44 mm',
    weight: '255.44 mm',
    thickness: '255.44 mm',
  },
  {
    title: 'PNS',
    image: PNS,
    length: '255.44 mm',
    width: '255.44 mm',
    weight: '255.44 mm',
    thickness: '255.44 mm',
  },
  {
    title: 'GRAIN ORIENTED COIL',
    image: GrainOriented,
    length: '255.44 mm',
    width: '255.44 mm',
    weight: '255.44 mm',
    thickness: '255.44 mm',
  },
  // {
  //   title: 'GAL VANISHED COIL',
  //   image: GalVanished,
  //   length: '255.44 mm',
  //   width: '255.44 mm',
  //   weight: '255.44 mm',
  //   thickness: '255.44 mm',
  // },
];

const FlatProduct = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen size is small

  return (
    <GradientContainer>
      <Box sx={{ padding: 4, backgroundColor: '', display: 'flex', justifyContent: 'center' }}>
        <Grid container spacing={3} justifyContent="center">
          {products.map((product, index) => (
            <Grid 
              item xs={11} sm={6} md={3} key={index}
              sx={{ 
               
                display: 'flex',
                justifyContent: 'center',
              }}
            >
            <Card 
              sx={{ 
                width: '100%', // Width will adjust to its parent container or grid
                maxWidth: 345, // Maximum width for larger screens
                aspectRatio: '3 / 4', // Set aspect ratio (3:4 width to height ratio)
                borderRadius: '20px', 
                overflow: 'hidden', 
                margin: '0 auto', 
                display: 'flex', 
                flexDirection: 'column'
              }}
            >
              <Box 
                sx={{
                  width: '100%',
                  flex: 1,
                  position: 'relative', // Ensures the image scales within this box
                }}
              >
                <CardMedia
                  component="img"
                  image={product.image}
                  alt={product.title}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover', // Ensures the image fills its container
                    position: 'absolute', // Prevents overflow
                    top: 0,
                    left: 0,
                  }}
                />
              </Box>
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 2,
                }}
              >
                <Typography 
                  gutterBottom 
                  variant="h6" 
                  component="div" 
                  sx={{ 
                    fontFamily: 'Bebas Neue', 
                    fontSize: '33px', 
                    fontWeight: 400, 
                    textAlign: 'center', 
                    color: "#7371FC" 
                  }}
                >
                  {product.title}
                </Typography>
              </CardContent>
            </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </GradientContainer>
  );
};

export default FlatProduct;
