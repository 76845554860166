import React from 'react'
import Details from '../components/Details';

const Contact = () => {
  return (
    <div id="contact-section" style={{backgroundImage: "linear-gradient(to right, #976BE4, #533B7E)",padding:"7.5%"}}>
    <Details />
    </div>
  )
}

export default Contact