import React, { useEffect } from 'react'
import GetInTouch from '../components/GetInTouch';
import GetStarted from '../components/GetStarted';
import Content from '../components/Content';
import Gallery from '../components/Gallery';
import Header from '../components/Header';
import Contact from './Contact';
import Details from '../components/Details';
import { div } from 'framer-motion/client';
const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    
    <Header />
    <GetStarted />
    <Contact />
    
    </>

  )
}

export default Home