import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles

// Images
import headerImg from "../components/images/image1.png";
import headerImg2 from "../components/images/image2.png";
import headerImg3 from "../components/images/image3.png";
import headerImg4 from "../components/images/image4.png";

const images = [headerImg, headerImg2];

const Header = () => {
  const CustomBox = styled(Box)(({ theme }) => ({
    position: "relative",
    minHeight: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(0),
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  }));

  const OverlayBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: 0,
    left: 40,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    padding: theme.spacing(2),
    boxSizing: "border-box",
    alignItems: "flex-start",
    zIndex: 2,
  }));

  const DarkOverlay = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)", // Dark overlay with 50% opacity
    zIndex: 1, // Make sure it's layered behind the text but on top of the image
  }));

  return (
    <CustomBox component="header">
      {/* Image Carousel */}
      <Carousel
        autoPlay
        infiniteLoop
        interval={3000}
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        transitionTime={1000}
        swipeable
        emulateTouch
        axis="horizontal"
        renderIndicator={() => null}
      >
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Slide ${index}`} style={{ width: "100%", height: "100vh", objectFit: "cover" }} />
          </div>
        ))}
      </Carousel>

      <DarkOverlay />
      {/* Overlay Box for Text and Button */}
      <OverlayBox>
        <Typography
          variant="h2"
          component="h1"
          sx={{
            fontWeight: 800,
            fontSize: { xs: "40px", sm: "56px", md: "64px", lg: "96px", xl: "135px" },
            fontFamily: "Inter, sans-serif",
            lineHeight: { xs: "48px", sm: "64px", md: "100px", lg: "163.38px" },
            letterSpacing: "0.06em",
           
          }}
        >
          GILLANI ENTERPRISES
        </Typography>
         <Typography
          variant="h5"
          component="h2"
          sx={{
            fontWeight: 500,
            fontSize: { xs: "16px", sm: "20px", md: "24px" },
            fontFamily: "Inter, sans-serif",
            letterSpacing: "0.02em",
            color: "#fff",
            marginTop: { xs: 1, sm: 2 },
            textAlign: "left",
            maxWidth: "600px"
          }}
        >
          Importer, Indenter & General Order Supplier All Kind of Iron & Steel
        </Typography>
        <Typography
          variant="p"
          component="p"
          sx={{
            py: 3,
            fontSize: { xs: "1rem", sm: "1.5rem", md: "2rem" },
            fontFamily: "Inter, sans-serif",
            lineHeight: { xs: "1.5rem", sm: "2rem", md: "3rem" },
            fontWeight: 600,
          }}
        >
          Steel Solutions, Global Connections
        </Typography>

        <Button
          variant="contained"
          onClick={() => {
            const contactSection = document.getElementById("contact-section");
            if (contactSection) {
              contactSection.scrollIntoView({ behavior: "smooth" });
            }
          }}
          sx={{
            width: { xs: "100%", sm: "285px" }, 
            maxWidth: "285px",
            height: { xs: "50px", md: "62px" },
            fontSize: { xs: "0.8rem", md: "0.9rem" },
            textTransform: "capitalize",
            borderRadius: 0,
            borderColor: "#14192d",
            color: "#fff",
            background: "linear-gradient(90deg, #7371FC, #966BC1)",
            px: { xs: 3, md: 4 },
            py: { xs: 1, md: 1.5 },
            "&&:hover": {
              background: "linear-gradient(90deg, #7371FC, #966BC1)",
            },
          }}
        >
          <Typography
          sx={{
            fontWeight: 400,
            fontSize: 30,
            fontFamily: "Bebas Neue, sans-serif",
            lineHeight: 36,
            
          }}
        >
          Contact Us
          </Typography>
        </Button>
      </OverlayBox>
    </CustomBox>
  );
};

export default Header;
