import React, { useRef, useEffect, useState } from "react";
import Globe from "react-globe.gl";
import { AmbientLight, DirectionalLight, SpotLight } from "three";
import { MeshPhongMaterial } from "three";
import countries from "./data/custom.geo.json";
import lines from "./data/lines.json";
import map from "./data/map.json";

const GlobeScene = () => {
  const globeEl = useRef();
  const [arcsProcessed, setArcsProcessed] = useState(false);
  const [globeSize, setGlobeSize] = useState({
    width: window.innerWidth <= 750 ? 350 : 600,
    height: window.innerWidth <= 750 ? 350 : 600
  });

  let Lines = [];
  let Map = [];
  if (map) {
    Map = map.maps;
  }
  if (lines) {
    Lines = lines.pulls;
  }

  // Update globe size on window resize
  useEffect(() => {
    const handleResize = () => {
      const newSize = window.innerWidth <= 750 ? 350 : 600;
      setGlobeSize({
        width: newSize,
        height: newSize
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const globe = globeEl.current;
    const controls = globe.controls();
    controls.minDistance = 300;
    controls.maxDistance = 300;
    const camera = globeEl.current.camera();
    const directionalLight = new DirectionalLight("white", 1.5);
    const ambientLight = new AmbientLight("white", 1.5);
    const spotLight = new SpotLight("white", 1000);
    directionalLight.position.copy(camera.position);
    globeEl.current.lights([ambientLight]);
    globeEl.current.scene().add(directionalLight);
    globeEl.current.controls().autoRotate = true;
    globeEl.current.controls().autoRotateSpeed = 0.5;
    setArcsProcessed(true);
  }, [arcsProcessed]);

  return (
    <div style={{ overflow: "hidden", maxWidth: "100%", textAlign: "center" }}>
      <Globe
        ref={globeEl}
        pointsMerge={true}
        pointAltitude={0.01}
        pointRadius={0.05}
        pointColor={() => "white"}
        pointsData={Map}
        labelAltitude={0.01}
        labelResolution={6}
        labelText={"city"}
        labelSize={(d) => 0.5 + d.size}
        labelDotRadius={0.3}
        labelColor={() => "white"}
        labelsData={Map}
        arcDashInitialGap={(e) => e.order}
        arcsTransitionDuration={1000}
        arcDashAnimateTime={(d) => 1000}
        arcDashGap={4}
        arcDashLength={0.9}
        arcStroke={0.5}
        arcColor={() => "white"}
        arcAltitude={(e) => e.arcAlt}
        arcsData={arcsProcessed ? Lines : []}
        // hexPolygonResolution={3}
        // hexPolygonColor={() => "#ffffff"}
        // hexPolygonMargin={0.7}
        // hexPolygonsData={arcsProcessed ? countries.features : []}
        animateIn={true}
        backgroundColor={"rgba(255, 0, 0, 0.0)"}
        showGlobe={true}
        atmosphereAltitude={0.25}
        atmosphereColor={"grey"}
        // globeMaterial={new MeshPhongMaterial({ color: "#533B7E" })}
        globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
        bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
        width={globeSize.width}
        height={globeSize.height}
      />
    </div>
  );
};

export default GlobeScene;
