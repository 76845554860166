import { Typography } from '@mui/material'
import React from 'react'

const FooterLink = ({ text }) => {
  return (
    <Typography
    variant='p'
    sx={{
      fontSize: '0.9rem',
      fontWeight: '400',
      textDecoration: 'none',
      color: '#414141',
      "&:hover": {
        color: '#1c2859',
      }
    }}
    >
      {text}
    </Typography>
  )
}

export default FooterLink