import React, { useState } from 'react';
import { Box, styled, Tabs, Tab, useTheme, useMediaQuery } from '@mui/material';
import FlatProduct from '../components/FlatProducts';
import ScrapProducts from '../components/ScrapProducts';
import LongProducts from '../components/LongProducts';

export const GradientContainer = styled(Box)(({ theme, bgColor }) => ({
  backgroundImage: bgColor === 'white' ? 'none' : 'linear-gradient(to right, #976BE4, #533B7E)',
  backgroundColor: 'linear-gradient(to right, #976BE4, #533B7E)',
  borderRadius: '0px',
  width: '100%',
  height: 'auto',
  overflowX: 'hidden',
  marginTop: '40px',
}));

const AllProducts = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is small

  const StyledTabs = styled(Tabs)(({ theme }) => ({
    borderRadius: '30px',
    background: '#ffffff',
    width: isSmallScreen ? '80%' : '60%', // Adjust width for small screens
    margin: '0 auto',
    marginTop: '5%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  }));

  const StyledTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 'bold',
    borderRadius: '30px',
    fontFamily: 'Bebas Neue',
    fontWeight: 400,
    fontSize: isSmallScreen ? '12px' : '33px', // Adjust font size for small screens
    color: '#7371FC',
    flexGrow: 1,
    backgroundColor: 'transparent',
    '&.Mui-selected': {
      backgroundColor: '#2252FF',
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: '#E5E5E5',
    },
  }));

  function CustomTabs({ value, handleChange }) {
    return (
      <div style={{ overflow: 'hidden' }}>
        <Box>
          <StyledTabs value={value} onChange={handleChange} centered>
            <StyledTab label="Flat Products" />
            <StyledTab label="Scrap Products" />
            <StyledTab label="Long Products" />
          </StyledTabs>
        </Box>
      </div>
    );
  }

  const [bgColor, setBgColor] = useState('transparent');
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    // Update background color based on the selected tab
    setBgColor(newValue === 1 ? 'white' : 'transparent');
  };

  return (
    <GradientContainer bgColor={'linear-gradient(to right, #976BE4, #533B7E)'}>
      <CustomTabs value={tabIndex} handleChange={handleChange} />
      <Box sx={{ color: '#fff' }}>
        {tabIndex === 0 && <FlatProduct />}
        {tabIndex === 1 && <ScrapProducts />}
        {tabIndex === 2 && <LongProducts />}
      </Box>
    </GradientContainer>
  );
};

export default AllProducts;
