// import React from 'react'
import React from 'react';
import {  
    Box,
    Button,
    Stack,
    TextField,
    Typography,
    Grid
} from '@mui/material'

import emailjs from '@emailjs/browser';

import 'react-toastify/dist/ReactToastify.css';
import Title from './Title'
import Paragraph from './Paragraph'
import 'react-toastify/dist/ReactToastify.css';
import { Transition } from 'react-transition-group';
import { styled } from '@mui/system';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseIcon from '@mui/icons-material/Close';

const Details = () => {


    const handleSubmit = (event) => {
        event.preventDefault();
        emailjs.sendForm(
            "service_67hkb09",
        "template_olim5lv",
        event.target,
        "MnTNZiZsILPnSMvRw",).then(res =>{
            console.log(res)
        } ).catch(err => 
            console.log(err)
        )
        };
    
        const CustomTypography = styled(Typography)({
            fontSize: "22px",
            textAlign: "center",
            lineHeight: "1",
            color: "#fff",
            marginTop: "1.5rem",
            fontWeight: 300,
            fontFamily: "inter, sans-serif",
          });
          const CustomTypography2 = styled(Typography)({
            fontSize: "22px",
            textAlign: "center",
            lineHeight: "1",
            color: "#fff",
            marginTop: "1.5rem",
            fontWeight: 300,
            fontFamily: "",
          });

    return (
        <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ py: 2, px: 2 }}>
          <Title
  textAlign="center"
  text={
    <>
      <Typography
        component="span"
        sx={{
          fontFamily: "Inter, sans-serif",
          fontWeight: 700,
          background: "#fff", 
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontSize: {
            xs: "30px", 
            sm: "36px", 
            md: "48px", 
            lg: "60px", 
          },
          lineHeight: {
            xs: "1.2", 
            sm: "1.4", 
            md: "1.5", 
            lg: "1.6",
          },
          textAlign: {
            xs: "center", 
            sm: "center",
            md: "center",
            lg: "center",
          },
        }}
      >
        Get in Touch with Us
      </Typography>
    </>
  }
/>
            
            <CustomTypography2 
            maxWidth = {'sm'}
            mx={0}
            textAlign={'center'}
            >
                Build with strength and precision. Connect with Gillani Enterprise for expert processing, consulting, and more
            </CustomTypography2>

            <Box 
  component="form" 
  noValidate 
  onSubmit={handleSubmit} 
  sx={{ 
      mt: 1,
      py: 1
  }}>
  <TextField
    margin="normal"
    required
    fullWidth
    id="name"
    label="Name"
    name="name"
    autoComplete="name"
    autoFocus
    sx={{
      backgroundColor: "#fff",
      '& .MuiInputLabel-root': {
        color: '', // Label color when not focused
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: 'black', // Label color when focused
      }
    }}
  />
  
  <TextField
    margin="normal"
    required
    fullWidth
    id="email"
    label="Email Address"
    name="email"
    autoComplete="email"
    autoFocus
    sx={{
      backgroundColor: "#fff",
      '& .MuiInputLabel-root': {
        color: '', // Label color when not focused
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: 'black', // Label color when focused
      }
    }}
  />
  
  <TextField
    margin="normal"
    required
    fullWidth
    name="phone"
    label="Phone Number"
    type="phone"
    id="phone"
    autoComplete="current-phone"
    sx={{
      backgroundColor: "#fff",
      '& .MuiInputLabel-root': {
        color: '', // Label color when not focused
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: 'black', // Label color when focused
      }
    }}
  />


                <Button 
                variant="contained" 
                fullWidth
                type="submit"
                size="medium"
                sx= {{ 
                    fontSize: '0.9rem',
                    textTransform: 'capitalize', 
                    py: 2,
                    mt: 3, 
                    mb: 2,
                    mt:7,
                    fontFamily: "Bebas Neue, sans-serif",
                    fontSize: "33px",
                    fontWeight: 400,
                    lineHeight: "39.6px",
                    borderRadius: 0,
                    background: "linear-gradient(90deg, #7371FC, #966BC1)",
                    "&:hover": {
                        backgroundColor: 'nightblue',
                    }
                }}
                >
                    SUBMIT INQUIRY
                </Button>
            </Box>
        </Grid>
    )
}

export default Details;