import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  List,
  ListItem,
  styled,
  ListItemButton,
  ListItemText,
  Drawer,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import DrawerItem from "./DrawerItem";
import logo from '../components/images/logoM.png';
import MenuIcon from '@mui/icons-material/Menu';


// Styled components
const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const ListMenu = styled(List)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

const itemList = [
  { text: "Home", to: "home-section" },
  { text: "Product", to: "product-section" },
  { text: "Contact", to: "contact-section" },
];

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const handleScroll = (id) => {
    navigate("/");
    setTimeout(() => {
      if (id === "home-section") {
        window.scrollTo(0, 0);
      } else {
        const navbarHeight = document.querySelector('nav').offsetHeight;
        const element = document.getElementById(id);
        if (element) {
          const elementPosition = element.getBoundingClientRect().top + window.scrollY - navbarHeight;
          window.scrollTo({
            top: elementPosition,
            behavior: "smooth",
          });
        }
      }
      setDrawerOpen(false); // Close the drawer after clicking an item
    }, 50);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };


    const [size, setSize] = useState({ width: "60%", height: "60%" });
  
    useEffect(() => {
      const updateSize = () => {
        if (window.innerWidth >= 600) {
          // For laptop and larger screens
          setSize({ width: "60%", height: "60%" });
        } else {
          // For mobile screens
          setSize({ width: "40%", height: "40%" });
        }
      };
  
      // Update size on window resize
      window.addEventListener('resize', updateSize);
  
      // Set the initial size
      updateSize();
  
      // Cleanup the event listener on component unmount
      return () => window.removeEventListener('resize', updateSize);
    }, []);
  return (
    <AppBar component="nav" position="fixed" sx={{ color: 'black', backgroundColor: 'white',zIndex: 1100 }} elevation={0}>
      <StyledToolbar>
      <Box sx={{ display: 'flex', alignItems: 'center', margin: 0 }}>
  <img src={logo} alt="Logo" style={{ width: size.width, maxWidth: "204px", height: size.height, marginTop: "8px" }} />
</Box>


        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
          <Drawer open={drawerOpen} onClose={toggleDrawer(false)}>
            <List>
              {itemList.map(({ text, to }) => (
                <ListItem key={text}>
                  <ListItemButton onClick={() => handleScroll(to)}>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>
          <ListItemButton onClick={toggleDrawer(true)}>
          <MenuIcon />
          </ListItemButton>
        </Box>

        <ListMenu>
          {itemList.map(({ text, to }) => (
            <ListItem key={text}>
              <ListItemButton onClick={() => handleScroll(to)}>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </ListMenu>
      </StyledToolbar>
    </AppBar>
  );
};

export default Navbar;
