import React, { useState } from "react";
// mui
import { Typography, Box, Stack } from "@mui/material";
// carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
// components
import Title from "./Title";
import Paragraph from "./Paragraph";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { styled } from "@mui/system";

const Gallery = () => {
  const [anchor, setAnchor] = React.useState(null);

  const handleClick = (event) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);
  const id = open ? "simple-popper" : undefined;

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        py: 8,
        px: 2,
        display: { xs: "flex" },
      }}
    >
      <Box
        component="section"
        sx={{
          paddingBottom: 3,
        }}
      >
        <Title
          text={"WE HAVE VAST AMOUNT OF UNIVERSITIES ON US"}
          textAlign={"center"}
        />

        <Paragraph
          text={
            "We have 100+ universities in different countries\
                    which you  may find appealing.\
                    Browse here and If you interested,\
                    contact us."
          }
          maxWidth={"sm"}
          mx={"auto"}
          textAlign={"center"}
        />
      </Box>

      <Box
        sx={{
          maxWidth: 700,
          width: "100%",
        }}
      >
        <Title text={"UK UNIVERSITIES"} textAlign={"center"} />
        <Paragraph
          text={`Durham University  - 
    University of Sussex  -
    University of Dundee  -
    Loughborough University  -
    University of Kent  -
    University of Essex  -
    University of Reading  -
    Coventry University  -
    London South Bank University  -
    University of West Scotland  -
    University of Liverpool -
    Heriot Watt University  -
    Nottingham Trent University  -
    De Montfort University  -
    Queens University Belfast  -
    University of Bradford  -
    Middlesex University  -
    University of East London  -
    The University of Ulster  -
    University of Bedfordshire  -
    Birmingham City University  -
    Bolton University  -
    Bangor University  -
    University of Lincoln`}
          maxWidth={"sm"}
          mx={"auto"}
          textAlign={"center"}
        />
      </Box>
      <Box
        sx={{
          maxWidth: 700,
          width: "100%",
        }}
      >
        <Title text={"CANADIAN UNIVERSITIES"} textAlign={"center"} />
        <Paragraph
          text={`Wilfrid Laurier University  - 
    Northeastern University  -
    Seneca College   -
    Thompson Rivers University  -
    University of Northern British Colmbia  -
    Victoria University  - 
	Douglas College  - 
	University of Saskatchewan  - 
	Northern Alberta Institute of Technology  - 
	Trent University  - 
	University of Windsor  - 
	Lambton College  - 
	Toronto Metropolitan University International College  - 
	Wilfrid Laurier International College  - 
	Vancouver Island University  - 
	University Canada West  - 
	North Island College  - 
	University of Regina  - 
	MacEwan University  - 
	Canadian Institute of Osteopathic Therapy  - 
	International College of Manitoba  - 
	Memorial University (Grenfell Campus)  - 
	University of Waterloo  - 
	Fairleigh Dickinson University  - 
	University of Fraser Valley  - 
	Adler University  - 
	Capilano University  - 
	Fraser International College  - 
	East Coast International College  - 
	Stfx University
`}
          maxWidth={"sm"}
          mx={"auto"}
          textAlign={"center"}
        />
      </Box>
      <Box
        sx={{
          maxWidth: 700,
          width: "100%",
        }}
      >
        <Title text={"USA UNIVERSITIES"} textAlign={"center"} />
        <Paragraph
          text={`	Mercer University  -  
	Northeastern University  - 
	Central Methodist University  - 
	Edgewood college  - 
	Johns Hopkins University  - 
	University of Lynchburg  - 
	Roosevelt University  -  
	Virginia Wesleyan University  - 
	Rutgers University Camden campus  - 
	Moravian University  -
	Bellarmine University   -
	Fairfield University  - 
	University of Charleston  - 
    Lewis University  - 
	Shenandoah University  - 
	Oklahoma city University  - 
	University of saint marry (Kansas)  - 
	Barton college  - 
    Belmont University  - 
	Randolph University  - 
	Tusculum University  - 
	Jacksonville University  - 
	Palm beach Atlantic University  - 
	Lakeland University  - 
	Robert Morris University  - 
	Towson University  - 
	Trinity Christian  - 
	Wentworth Institute of Technology

`}
          maxWidth={"sm"}
          mx={"auto"}
          textAlign={"center"}
        />
      </Box>
      <Box
        sx={{
          maxWidth: 700,
          width: "100%",
        }}
      >
        <Title text={"AUSTRALIAN UNIVERSITIES"} textAlign={"center"} />
        <Paragraph
          text={`	Murdoch University  -
	Curtin College  - 
	Southern Cross University  - 
	ECU (Edith Cowan University)  - 
	Swinburne University  - 
	Deakin College  -
	Federation University  -
	Western Sydney University  -
	Charles Strut University  -
	CIC Higher Education  -
	RMIT  -
	La Trobe College  - 
	University of Technology Sydney  -
	Australia Institute of Higher Education  - 
	The University of Newcastle  - 
	University of Wollongong  -
	Flinders University  - 
	SAIBT  - 
	Griffith University  -
	University of Canberra College


`}
          maxWidth={"sm"}
          mx={"auto"}
          textAlign={"center"}
        />
      </Box>
    </Stack>
  );
};

export default Gallery;
