import React from "react";
import { Box, styled, Typography, Card, CardContent, CardMedia, Grid,Button } from "@mui/material";
import Title from "./Title";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Image
import Card1 from "../components/images/Card1.png";
import Card2 from "../components/images/Card2.png";
import Card3 from "../components/images/Card3.png";
import tp1 from "../components/images/25.png"
import tp2 from "../components/images/26.png"
import tp3 from "../components/images/27.jpg"
import tp4 from "../components/images/28.png"
import tp5 from "../components/images/29.png"
import tp6 from "../components/images/hunzasteelwhite.jpg"

import GlobeScene from "./GlobeScene";
import Contact from '../pages/Contact';
import { Link } from 'react-router-dom';

const GetStarted = () => {

  const CustomCard = styled(Card)(({ theme }) => ({
    maxWidth: 380,   
    height: 534, 
    width: "100%", 
    margin: "20px",       
    borderRadius: "15px", 
    boxShadow: "0 6px 18px rgba(0, 0, 0, 0.1)", 
    backgroundColor:"#FFBEFF",
    [theme.breakpoints.down('sm')]: {
      height: 'auto', 
      // marginLeft:'40px'
      marginRight:'0px'
    }
  }));

  const CustomCardMedia = styled(CardMedia)(({ theme }) => ({
    height: '247.48px', 
    width: '314px',
    borderRadius: '5px 0 0 0', 
    margin: 32,
    [theme.breakpoints.down('sm')]: {
      width: '100%', // Full width on smaller screens
      margin: 0,
      borderRadius: '15px 15px 0 0', 
    }
  }));

  const CustomTypography = styled(Typography)(({ theme }) => ({
    fontSize: "26px",
    textAlign: "left",
    lineHeight: "1.5",
    color: "#656594",
    marginTop: "1.5rem",
    fontWeight: 400,
    fontFamily: "Oxygen, sans-serif",
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px', // Smaller font on small screens
      textAlign: "left",
    },
  }));

  const CustomTypography2 = styled(Typography)(({ theme }) => ({
    fontSize: "33px",
    textAlign: "center",
    lineHeight: "1.5",
    color: "#533B7E",
    marginTop: "1.5rem",
    fontWeight: 400,
    fontFamily: "Bebas Neue, sans-serif",
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px', // Smaller font on small screens
      textAlign: "center",
    },
  }));

  const logoSettings = {
    dots: false,
    infinite: true,
    speed: 12000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  
  const reversedLogoSettings = {
    ...logoSettings,  
    rtl: true,        
  };

  
  return (
    <div>
      <Grid
        container
        spacing={4}
        sx={{
          py: 10,
        }}
      >
      
        <Grid item xs={12} md={6} sx={{ display: "flex", py: { xs: 5, md: 21 } }}>
          <Box sx={{ textAlign: { xs: "center", md: "left" }, margin: "1.5%", width: "100%" }}>
            <Title
              textAlign="center"
              text={
                <>
                  <Typography
                    component="span"
                    sx={{
                      fontFamily: "Bebas Neue, sans-serif",
                      fontSize: { xs: "60px", sm: "65px", md: "85px" }, // Responsive font-size
                      fontWeight: 400,
                      lineHeight: { xs: "50px", sm: "60px", md: "75px" },
                      background: "linear-gradient(90deg, #7E7CCA, #FF0000)", 
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    COMPANY
                  </Typography>
                  &nbsp;
                  <Typography
                    component="span"
                    sx={{
                      fontFamily: "Bebas Neue, sans-serif",
                      fontSize: { xs: "60px", sm: "65px", md: "85px" },
                      fontWeight: 400,
                      lineHeight: { xs: "50px", sm: "60px", md: "75px" },
                      background: "linear-gradient(90deg, #CDC1FF, #0D0045)", 
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    OVERVIEW
                  </Typography>
                </>
              }
            />
            <CustomTypography sx={{ width: { xs: "80%", md: "75%",xl:"70%" }, margin: "0 auto", textAlign: "left" }}>
              <br />
              <b>Gillani Enterprises (GE)</b> is recognized as one of Pakistan's leading indenting companies, specializing in the steel and metal industry. With a focus on providing high-quality solutions, GE has built a strong global presence, serving clients in over 30 countries. Our substantial annual turnover reflects our commitment to excellence and customer satisfaction in the steel market. Our expertise in steel indenting is at the core of our operations, ensuring that we deliver comprehensive services tailored to meet diverse industrial needs.<br/><br/>
              <b>Our Product Range:</b><br/><br/>
              Gillani Enterprises offers an extensive variety of steel and metal products, including:<br/>
              <ul>
                <li>Steel Billets</li>
                <li>Wire Rods</li>
                <li>Reinforcement Bars</li>
                <li>Galvanneal</li>
                <li>Electro-Galvanize</li>
                <li>Cold Rolled (CR) and Hot Rolled (HR) steel</li>
                <li>Stainless steel coils and sheets</li>
              </ul>
              With a vision for future growth, GE is actively expanding its global footprint by establishing new supply ventures, aiming to bring our expertise to emerging markets worldwide.
            </CustomTypography>
          </Box>
        </Grid>

        {/* Right Column: Globe Scene */}
        <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              marginRight: { md: 0, xs: 0 },
            }}
          >
            <GlobeScene />
          </Box>
        </Grid>

        {/* Steel Products Section */}
        <Box id="product-section" sx={{ textAlign: "center", padding: "20px", width: "100%", backgroundImage: "linear-gradient(to right, #976BE4, #533B7E)", boxSizing: "border-box" }}>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Bebas Neue, sans-serif",
              fontSize: { xs: "40px", sm: "50px", md: "85px" }, // Responsive font size
              fontWeight: 400,
              lineHeight: { xs: "40px", sm: "60px", md: "75px" },
              color: "#fff",
              textTransform: "uppercase",
            }}
          >
            COMPREHENSIVE STEEL PRODUCTS
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap", gap: 2, mb: 2 }}>
            {/* Card 1 */}
            <CustomCard>
              <CustomCardMedia image={Card1} title="Flat Products" />
              <CardContent>
                <Typography variant="body2" sx={{color:"#31355A",fontWeight: 400, fontsize:"17px",justifyContent: "center",}}>
                  Galvanized Flat Steel Products produced by coating both surfaces of cold rolled metal with a layer of zinc by hot dipping process.
                </Typography>
                <CustomTypography2 variant="h6" sx={{ mt: 2 }}>
                  FLAT PRODUCTS
                </CustomTypography2>
              </CardContent>
            </CustomCard>

            {/* Card 2 */}
            <CustomCard>
              <CustomCardMedia image={Card2} title="Long Products" />
              <CardContent>
                <Typography variant="body2" sx={{color:"#31355A",fontWeight: 400, fontsize:"17px",justifyContent: "center",}}>
                  Galvanized Flat Steel Products produced by coating both surfaces of cold rolled metal with a layer of zinc by hot dipping process.
                </Typography>
                <CustomTypography2 variant="h6" sx={{ mt: 2 }}>
                  LONG PRODUCTS
                </CustomTypography2>
              </CardContent>
            </CustomCard>

            {/* Card 3 */}
            <CustomCard>
              <CustomCardMedia image={Card3} title="Scrap Products" />
              <CardContent>
                <Typography variant="body2" sx={{color:"#31355A",fontWeight: 400, fontsize:"17px",justifyContent: "center",}}>
                  Galvanized Flat Steel Products produced by coating both surfaces of cold rolled metal with a layer of zinc by hot dipping process.
                </Typography>
                <CustomTypography2 variant="h6" sx={{ mt: 2 }}>
                  SCRAP PRODUCTS
                </CustomTypography2>
              </CardContent>
            </CustomCard>
          </Box>

          <Box sx={{ mt: 4 }}>
          <Link to="/allproducts" onClick={() => window.scrollTo(0, 0)} style={{ textDecoration: 'none' }}>
          <Button
                variant="contained" 
                
                type="submit"
                size="medium"
                sx= {{ 
                   
                    textTransform: 'capitalize', 
                    py: 2,
                    mt: 3, 
                    mb: 2,
                    mt:7,
                    fontFamily: "Bebas Neue, sans-serif",
                    fontSize: "30px",
                    fontWeight: 400,
                    lineHeight: "36px",
                    borderRadius: 0,
                    background: "linear-gradient(90deg, #7371FC, #966BC1)",
                    "&:hover": {
                        backgroundColor: 'nightblue',
                    }
                }}
                >
                    VIEW ALL PRODUCTS
                </Button>
            </Link>
          </Box>
        </Box>

        {/* Partners and Clients Section */}
        <Box sx={{ textAlign: "center", padding: "40px", width: "100%", boxSizing: "border-box", background: "#fff" }}>
          <Typography
            component="span"
            sx={{
              fontFamily: "Bebas Neue, sans-serif",
              fontSize: { xs: "40px", sm: "65px", md: "85px" },
              fontWeight: 400,
              lineHeight: { xs: "50px", sm: "60px", md: "75px" },
              background: "linear-gradient(90deg, #7E7CCA, #FF0000)", 
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              overflow: "hidden",
            }}
          >
            TRUSTED PARTNERS AND CLIENTS
          </Typography>

          <Box sx={{ mt: 4 }}>
        <Slider {...logoSettings}>
          <div>
            <img src={tp1} alt="Trusted Partner 1" style={{width: "450px",height:'450px', margin: "0 auto",borderRadius:'50px' }} />
          </div>
          <div>
            <img src={tp2} alt="Trusted Partner 2" style={{width: "450px",height:'450px', margin: "0 auto",borderRadius:'50px' }} />
          </div>
          <div>
            <img src={tp3} alt="Trusted Partner 3" style={{ width: "450px",height:'450px', margin: "0 auto",borderRadius:'0px' }} />
          </div>
          <div>
            <img src={tp4} alt="Trusted Partner 4" style={{ width: "450px",height:'450px', margin: "0 auto",borderRadius:'50px' }} />
          </div>
          <div>
            <img src={tp5} alt="Trusted Partner 4" style={{ width: "450px",height:'450px', margin: "0 auto",borderRadius:'50px' }} />
          </div>
          <div>
            <img src={tp6} alt="Trusted Partner 4" style={{ width: "350px",height:'450px', margin: "0 auto",borderRadius:'50px' }} />
          </div>
        </Slider>
      </Box>

      {/* Second slider: right-to-left */}
      <Box sx={{ mt: 4 }}>
        <Slider {...reversedLogoSettings}>
        <div>
        <img src={tp1} alt="Trusted Partner 1" style={{width: "450px",height:'450px', margin: "0 auto",borderRadius:'0px' }} />
          </div>
          <div>
            <img src={tp2} alt="Trusted Partner 2" style={{ width: "450px",height:'450px', margin: "0 auto",borderRadius:'0px' }} />
          </div>
          <div>
            <img src={tp3} alt="Trusted Partner 3" style={{ width: "450px",height:'450px', margin: "0 auto",borderRadius:'0px' }} />
          </div>
          <div>
            <img src={tp4} alt="Trusted Partner 4" style={{ width: "450px",height:'450px', margin: "0 auto",borderRadius:'0px' }} />
          </div>
          <div>
            <img src={tp5} alt="Trusted Partner 4" style={{ width: "450px",height:'450px', margin: "0 auto",borderRadius:'0px' }} />
          </div>
          <div>
            <img src={tp6} alt="Trusted Partner 4" style={{ width: "350px",height:'450px', margin: "0 auto",borderRadius:'50px' }} />
          </div>
        </Slider>
      </Box>
        </Box>
      </Grid>

    </div>
  );
};

export default GetStarted;
