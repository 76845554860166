import React from 'react';
import { Box, Stack, styled, Typography, Link } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import FooterTitle from './FooterTitle';
import FooterLink from './FooterLink';
import logo from '../images/logoM.png';

const Footer = () => {
  
  const StackColumn = styled(Stack)(() => ({
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
    gap: 8,
    textAlign: 'center',
  }));

  const BoxRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fff',
    flex: 1,
    marginLeft:'10%',
    marginRight:'10%',
    justifyContent: 'space-between', // Ensures space between columns
    padding: '20px',
    position:'relative',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 30,
      
    }
  }));

  const CopyrightAndSocialSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
   
    padding: '10px 0',
    backgroundColor: '#f5f5f5',
    marginTop: '',
  }));

  const SocialIcons = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '15px',
    marginTop: '10px',
  }));

  const LogoWrapper = styled(Box)(({ theme }) => ({
    position: 'absolute',
    right: '5%',
    top: '50%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('md')]: {
      position: 'static', // Make logo part of the normal flow on small screens
      transform: 'none',
      marginBottom: '20px', // Add some spacing for the logo on mobile
    },
  }));
  
  const LogoImage = styled('img')(({ theme }) => ({
    width: '100px', // Adjust the size of the logo as needed
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '80px', // Smaller size on mobile
    },
  }));

  return (
    <>
      <BoxRow component="footer">
        {/* Contact Section */}
        <StackColumn style={{ alignItems: 'flex-start', textAlign: 'left' }}>
          <FooterTitle text={'Reach us'} />
          <FooterLink 
            text={(
              <>
                <FaPhoneAlt style={{ marginRight: '8px' }} />
                +92-300-1114901
              </>
            )} 
          />
          <FooterLink 
            text={(
              <>
                <FaEnvelope style={{ marginRight: '8px' }} />
                gillanienterprise@gmail.com
              </>
            )} 
          />
          <FooterLink 
            text={(
              <>
                <FaMapMarkerAlt style={{ marginRight: '8px' }} />
                Defence Raya, Fairway Commercial
              </>
            )} 
          />
          <FooterLink style={{ marginLeft: '8px' }}
            text={(
              <>
              Building No. 113, First Floor, DHA Phase 6 Lahore
              </>
            )} 
          />
          
        </StackColumn>

        {/* Company Section */}
        <StackColumn style={{ alignItems: 'flex-start' }}>
          <FooterTitle text={'Company'} />
          <FooterLink text={'About'} />
          <FooterLink text={'Services'} />
          <FooterLink text={'Contact'} />
        </StackColumn>

        {/* Services Section */}
        <StackColumn style={{ alignItems: 'flex-start' }}>
          <FooterTitle text={'Our Services'} />
          <FooterLink text={'Recycling Services'} />
          <FooterLink text={'Quality Assurance'} />
          <FooterLink text={'Steel Processing'} />
        </StackColumn>
        <LogoWrapper>
        <LogoImage src={logo} alt="Logo" />
      </LogoWrapper>
        </BoxRow>

      {/* Copyright and Social Media Section */}
      <CopyrightAndSocialSection>
        <Typography variant="body2">
          &copy; 2024 Copyright @Stan Consultants
        </Typography>
        
        {/* Social Media Icons */}
        <SocialIcons>
          <Link href="#" sx={{ color: '#414141', "&:hover": { color: '#1c2859' } }}>
            <FacebookIcon />
          </Link>
          <Link href="#" sx={{ color: '#414141', "&:hover": { color: '#1c2859' } }}>
            <TwitterIcon />
          </Link>
          <Link href="#" sx={{ color: '#414141', "&:hover": { color: '#1c2859' } }}>
            <InstagramIcon />
          </Link>
          <Link href="#" sx={{ color: '#414141', "&:hover": { color: '#1c2859' } }}>
            <LinkedInIcon />
          </Link>
        </SocialIcons>
      </CopyrightAndSocialSection>
    </>
  );
};

export default Footer;
