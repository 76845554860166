import React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid, Box,useTheme, useMediaQuery } from '@mui/material';
import MildSteelPlates from "../components/images/MildSteel.png"
import CobblePlateLowCarbon from "../components/images/CobblePlate.png"
import SquareSteelBillet from "../components/images/SquareSteel.png"
import LowCarbonWireRoad from "../components/images/LowCarbon.png"
import RoundSteelBillet from "../components/images/RoundSteel.png"
import { GradientContainer } from '../pages/AllProducts';
import GiBLACK from "../components/AllProductsImages/Long GI Black Bundle.jpg";
import LMS from "../components/AllProductsImages/Long LMS.jpg";
import Saria from "../components/AllProductsImages/Long Saria.jpg"
// Data for products (you can replace this with props or data fetched from an API)
const products = [
    {
        title: 'GI BLACK BUNDLE',
        image: GiBLACK, 
        length: '255.44 mm',
        width: '255.44 mm',
        weight: '255.44 mm',
        thickness: '255.44 mm',
      },
      {
        title: 'RAIL',
        image: LMS,
        length: '255.44 mm',
        width: '255.44 mm',
        weight: '255.44 mm',
        thickness: '255.44 mm',
      },
      {
        title: 'REBAR',
        image: Saria,
        length: '255.44 mm',
        width: '255.44 mm',
        weight: '255.44 mm',
        thickness: '255.44 mm',
      },
      {
        title: 'LOW CARBON WIRE ROAD',
        image: LowCarbonWireRoad,
        length: '255.44 mm',
        width: '255.44 mm',
        weight: '255.44 mm',
        thickness: '255.44 mm',
      },
      // {
      //   title: 'ROUND STEEL BILLET',
      //   image: RoundSteelBillet,
      //   length: '255.44 mm',
      //   width: '255.44 mm',
      //   weight: '255.44 mm',
      //   thickness: '255.44 mm',
      // }
];

const LongProducts = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <GradientContainer>
    <Box sx={{ padding: 4, backgroundColor: '', display: 'flex', justifyContent: 'center' }}>
      <Grid container spacing={3} justifyContent="center">
        {products.map((product, index) => (
          <Grid 
            item xs={11} sm={6} md={3} key={index}
            sx={{ 
             
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Card 
              sx={{ 
                width: '100%', // Width will adjust to its parent container or grid
                maxWidth: 345, // Maximum width for larger screens
                aspectRatio: '3 / 4', // Set aspect ratio (3:4 width to height ratio)
                borderRadius: '20px', 
                overflow: 'hidden', 
                margin: '0 auto', 
                display: 'flex', 
                flexDirection: 'column'
              }}
            >
              <Box 
                sx={{
                  width: '100%',
                  flex: 1,
                  position: 'relative', // Ensures the image scales within this box
                }}
              >
                <CardMedia
                  component="img"
                  image={product.image}
                  alt={product.title}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover', // Ensures the image fills its container
                    position: 'absolute', // Prevents overflow
                    top: 0,
                    left: 0,
                  }}
                />
              </Box>
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 2,
                }}
              >
                <Typography 
                  gutterBottom 
                  variant="h6" 
                  component="div" 
                  sx={{ 
                    fontFamily: 'Bebas Neue', 
                    fontSize: '33px', 
                    fontWeight: 400, 
                    textAlign: 'center', 
                    color: "#7371FC" 
                  }}
                >
                  {product.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  </GradientContainer>
  );
};

export default LongProducts;
